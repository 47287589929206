<template>
  <fieldset class="form__fieldset">
    <div class="row">
      <div class="col-12 mb-3">
        <b-form-checkbox v-model="value2" name="check-button" size="lg" switch>
          {{value2 ? 'Активен' : 'Не активен'}}
        </b-form-checkbox>
      </div>
    </div>
  </fieldset>
</template>

<script>

import FveFieldMixin from "@widgetFormValidate/src/Mixin/FveFieldMixin";

export default {
  mixins: [
    FveFieldMixin
  ],
  props: {
    // значение по умолчанию (можно переопределить тип)
    value    : { type: Boolean, default: false },
  },
  methods: {
    prepareValue($event) {
      return $event;
    },
    isEmpty(value) {
      return value === null;
    },
    // eslint-disable-next-line
    validateFunction(str) {
      return 'SUCCESS';
    },
  },
  computed: {
    value2: {
      get() {
        return this.value;
      },
      set(value) {
        this.inputFormElement(value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>

@import "~@widgetFormValidate/style/inputText.scss";

</style>
