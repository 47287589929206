<template>
  <TrainerEditTemplate v-if="user">
    <TrainerEditForm
      :formData="user"
      ref="userForm"
      :editForm="true"
    />
    <div class="btn-gp">
      <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
      <router-link :to="{name: this.$routeName.TRAINER_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
    </div>
  </TrainerEditTemplate>
</template>

<script>

import TrainerEditTemplate from "@page/Trainer/_component/TrainerEditTemplate";
import TrainerEditForm from '@component/Form/TrainerEditForm';

export default {
  name: "TrainerEdit",
  components: {
    TrainerEditTemplate,
    TrainerEditForm,
  },
  props: {
    trainerId: [String, Number]
  },
  data(){
    return {
      user: false,
      checked: false,
    };
  },
  methods:{
    save() {
      let formData = this.$refs.userForm.formSubmitGetData();
      if(!formData) { return; }

      let sendData = Object.assign({}, formData);

      sendData.mobile = sendData.mobile.replace(/-|\s+/g,"");
      //
      sendData.contact_set = [];

      /*if(sendData.contactTelegram  ) {*/ sendData.contact_set.push({ type: "TGRM", value: sendData.contactTelegram  || '' }); //}
      /*if(sendData.contactEmail     ) {*/ sendData.contact_set.push({ type: "MAIL", value: sendData.contactEmail     || '' }); //}
      /*if(sendData.contactTwitter   ) {*/ sendData.contact_set.push({ type: "TWTR", value: sendData.contactTwitter   || '' }); //}
      /*if(sendData.contactWhatsapp  ) {*/ sendData.contact_set.push({ type: "WTSP", value: sendData.contactWhatsapp  || '' }); //}
      /*if(sendData.contactInstagram ) {*/ sendData.contact_set.push({ type: "INST", value: sendData.contactInstagram || '' }); //}

      delete sendData.contactTelegram;
      delete sendData.contactEmail;
      delete sendData.contactTwitter;
      delete sendData.contactWhatsapp;
      delete sendData.contactInstagram;

      sendData.contact_set = JSON.stringify(sendData.contact_set);

      RequestManager.Trainer.updateTrainerById({
        id    : this.trainerId,
        user  : sendData
      }).then( (res) => {

        if(sendData.slotEnable) {

          let data = {
            owner     : this.user.self_family,
            status    : 'FREE',
            // span      : sendData.span,
            // repeat    : sendData.repeat.id,
            date      : sendData.date,
            start_time: sendData.start_time,
            end_time  : sendData.end_time,
          };

          if(sendData.repeat.id !== 'ONCE') {
            data.repeat = sendData.repeat.id;
            data.span   = sendData.span.id;
          }

          RequestManager.Trainer.addTrainerSlot({
            trainerId: this.trainerId,
            data     : data
          });
        }

        this.$toasts.push({
          message: 'Тренер успешно обновлен',
          duration: 4000,
        });
        this.$router.push({name: this.$routeName.TRAINER_LIST });
      });

    },
  },
  mounted() {
    RequestManager.Trainer.getTrainerById({
      id: this.trainerId
    }).then((user) => {
      /*
       * about: null
       * avatar: null
       * birthday: null
       * contact_set: []
       * email: "kiryl.konnikov@gmail.com"
       * first_name: "Кирилл"
       * height: null
       * id: 34
       * is_commoner: true
       * is_trainer: false
       * last_name: "Юзер"
       * middle_name: ""
       * mobile: "+79053903860"
       * tags: []
       * trainers: []
       * weight: null
       */
      this.user = Object.assign({}, user);

      for(let i = 0; i < this.user.contact_set.length; i++) {
        let obj = this.user.contact_set[i];
        switch (obj.type) {
          case 'TGRM': this.user.contactTelegram  = obj.value; break;
          case 'MAIL': this.user.contactEmail     = obj.value; break;
          case 'TWTR': this.user.contactTwitter   = obj.value; break;
          case 'WTSP': this.user.contactWhatsapp  = obj.value; break;
          case 'INST': this.user.contactInstagram = obj.value; break;
        }
      }

    });
  }
};
</script>

<style lang="scss" scoped>

</style>
