<template>
  <form class="form">

    <FveBlockSwitchActive
      v-if="editForm"
      v-model="form.is_active"
      class="fix-swich"
    />

    <fieldset class="form__fieldset">
      <legend class="form__legend">Вход в систему</legend>
      <div class="row">
        <div class="col-3">
          <FvePhone
            label="Номер телефона"
            name="mobile"
            required
            v-model="form.mobile"
          />
        </div>
      </div>
    </fieldset>

    <fieldset class="form__fieldset">
      <legend class="form__legend">Личная информация</legend>
      <div class="row">
        <div class="col-3">
          <FveFileImageCropperPreview
            label=""
            name="avatar"
            v-model="form.avatar"
          />
        </div>
        <div class="col-9">
          <div class="row">
            <div class="col-4">
              <FveText
                label="Фамилия"
                name="last_name"
                required
                v-model="form.last_name"
              />
            </div>
            <div class="col-4">
              <FveText
                label="Имя"
                name="first_name"
                required
                v-model="form.first_name"
              />
            </div>
            <div class="col-4">
              <FveText
                label="Отчество"
                name="middle_name"
                v-model="form.middle_name"
              />
            </div>
            <div class="col-12">
              <FveTextarea
                label="Информация о тренере"
                name="about"
                v-model="form.about"
              />
            </div>
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset class="form__fieldset">
      <legend class="form__legend">Направления</legend>
      <div class="row" v-if="tagList">
        <div class="col-12">
          <FveMultiSelectTag
            optionFieldId="tag"
            optionFieldName="tag"
            label="Направления"
            name="tags"
            v-model="form.tags"
            :options="tagList"
            @addTag="addTagList"
          />
        </div>
      </div>
    </fieldset>

    <fieldset class="form__fieldset">
      <legend class="form__legend">Контакты</legend>
      <div class="row row-cols-5">
        <div class="col">
          <FveText
            label="Телеграм"
            placeholder="@telegram"
            name="contact_telegram"
            v-model="form.contactTelegram"
          />
        </div>
        <div class="col">
          <FveText
            label="E-mail"
            placeholder="mail"
            name="contact_email"
            v-model="form.contactEmail"
          />
        </div>
        <div class="col">
          <FveText
            label="Twitter"
            placeholder="twitter"
            name="contact_twitter"
            v-model="form.contactTwitter"
          />
        </div>
        <div class="col">
          <FveText
            label="Whatsapp"
            placeholder="whatsapp"
            name="contact_whatsapp"
            v-model="form.contactWhatsapp"
          />
        </div>
        <div class="col">
          <FveText
            label="Instagram"
            placeholder="instagram"
            name="contact_instagram"
            v-model="form.contactInstagram"
          />
        </div>


        <div class="col-12 mb-3">
          <FveCheckbox
            label="Автосоздание слотов"
            v-model="form.slotEnable"
          />
        </div>

      </div>


      <div class="row row--fix" v-if="form.slotEnable">
        <div class="col-12">
          <div class="row">
            <div class="col-3">
              <FveSelectObject
                label="Повторять"
                name="repeat-1dfsz"
                required
                v-model="form.repeat"
                :optionList="[
                  { id: 'ONCE', name: 'Однократно'    },
                  { id: 'DALY', name: 'Каждый день'   },
                  { id: 'WKLY', name: 'Каждую неделю' },
                  { id: 'MNLY', name: 'Каждый месяц'  },
                ]"
                :optionGetKey="obj => obj.id"
                :optionGetName="obj => obj.name"
              />
            </div>
            <div class="col-3">
              <FveSelectObject

                v-if=" form.repeat && form.repeat.id !== 'ONCE' "

                label="Повторять в течение"
                name="span-1dfsz"
                required
                v-model="form.span"
                :optionList="[
                  { id: 'MNTH', name: 'Месяца'    },
                  { id: 'HLFY', name: 'Полугода'  },
                  { id: 'YEAR', name: 'Года'      },

                ]"
                :optionGetKey="obj => obj.id"
                :optionGetName="obj => obj.name"
              />
            </div>
          </div>

        </div>


        <div class="col-12">
          <div class="row">
            <div class="col-3">
              <FveDatePicker
                label="День"
                name="date"
                required
                v-model="form.date"
              />
            </div>
            <div class="col-3">
              <FveTime
                label="Время начала"
                name="start_time"
                required
                v-model="form.start_time"
              />
            </div>
            <div class="col-3">
              <FveTime
                label="Время окончания"
                name="end_time"
                required
                v-model="form.end_time"
              />
            </div>
          </div>
        </div>
      </div>


    </fieldset>

  </form>
</template>

<script>

import FveText from "@widgetFormValidate/src/Element/Text/FveText";
import FveEmail from "@widgetFormValidate/src/Element/Text/FveEmail";
import FvePhone from "@widgetFormValidate/src/Element/Text/FvePhone";
// import FveLogin from "@widgetFormValidate/src/Element/Text/FveLogin";
import FveTextarea from "@widgetFormValidate/src/Element/Text/FveTextarea";
import FveFileImageCropperPreview from "@widgetFormValidate/src/Element/File/FveFileImageCropperPreview";
// import FveMultiSelect from "@widgetFormValidate/src/Element/MultiSelect/FveMultiSelect";
import FveMultiSelectTag from "@widgetFormValidate/src/Element/MultiSelect/FveMultiSelectTag";

//
import FveFormMixin from "@widgetFormValidate/src/Mixin/FveFormMixin";
import FveBlockSwitchActive from "@widgetFormValidate/src/Element/Switch/FveBlockSwitchActive";
import FveCheckbox from "@widgetFormValidate/src/Element/Checkbox/FveCheckbox";
import FveSelectObject from "@widgetFormValidate/src/Element/Select/vue-multiselect/FveSelectObject";
import FveDatePicker from "@widgetFormValidate/src/Element/Date/FveDatePicker";
import FveTime from "@widgetFormValidate/src/Element/Text/FveTime";

export default {
  mixins: [
    FveFormMixin
  ],
  props: {
    editForm: {type: Boolean, default: false}
  },
  components: {
    FveBlockSwitchActive,
    FveText,
    //FveEmail,
    FvePhone,
    // FveLogin,
    FveTextarea,
    FveFileImageCropperPreview ,
    // FveMultiSelect,
    FveMultiSelectTag,
    FveCheckbox,
    FveSelectObject,
    FveDatePicker,
    FveTime,
  },
  data(){
    return {
      tagList: [],
    };
  },
  methods: {
    formSchema() {
      return {
        //
        is_active    : { type: Boolean, default: () => { return true; } },
        //
        mobile       : { type: String, default: () => { return ''; } },
        username     : { type: String, default: () => { return ''; } },
        email        : { type: String, default: () => { return ''; } },
        //
        last_name    : { type: String, default: () => { return ''; } },
        first_name   : { type: String, default: () => { return ''; } },
        middle_name  : { type: String, default: () => { return ''; } },
        //
        birthday     : { type: String, default: () => { return ''; } },
        // TODO: test and fix
        weight       : { type: Number, default: () => { return ''; } },
        height       : { type: Number, default: () => { return ''; } },
        //
        about        : { type: String, default: () => { return ''; } },
        //
        avatar       : { type: FileClass, default: () => { return null; } },
        //
        tags         : { type: Array, default: () => { return []; } },

        contactTelegram   : { type: String, default: () => { return ''; } },
        contactEmail      : { type: String, default: () => { return ''; } },
        contactTwitter    : { type: String, default: () => { return ''; } },
        contactWhatsapp   : { type: String, default: () => { return ''; } },
        contactInstagram  : { type: String, default: () => { return ''; } },

        slotEnable        : { type: Boolean , default: () => { return false; } },

        date        : { type: String, default: () => { return (new DateTime()).format('YYYY-MM-DD'); } },
        start_time  : { type: String, default: () => { return ''; } },
        end_time    : { type: String, default: () => { return ''; } },
        repeat      : { type: Object, default: () => { return { id: 'ONCE', name: 'Однократно'    };  } },
        span        : { type: Object, default: () => { return null; } },
      };
    },

    addTagList(tagItem) {
      this.tagList.push(tagItem);
      this.form.tags.push(tagItem);
    }
  },
  mounted() {
    RequestManager.Reference.getTagList({}).then((tagObject) => {
      this.tagList = tagObject.results;
    });
  }
};

</script>

<style lang="scss" scoped>

// tags-list
.tags-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .tags-list__ell {
    display: block;
    margin-bottom: 6px;
    margin-right: 6px;
    padding: 10px 12px;
    color: #28a745;
    font-weight: 500;
    font-size: 17px;
    line-height: 1;
    border: 1px solid #28a745;
    border-radius: 16px;
  }
}
</style>
